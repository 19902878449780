<template>
  <div>
    <a-row>
       <a-col :span="8">
        <a-card :bordered="false">
          <div class="">
            <a-form layout="inline" label-align="left">
              <a-row>
                <a-col :span="12">
                    <a-form-item label="时间">
                      <a-space direction="vertical" :size="12">
                        <a-range-picker @change="registerDateChange" />
                      </a-space>
                    </a-form-item>
                  </a-col>

                <a-col :span="12">
                  <span>
                    <a-button type="primary" @click="loadData">筛选</a-button>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </a-card>
        <a-card
          style="margin-bottom: 24px"
          :loading="radarLoading"
          :bordered="false"
          :body-style="{ padding: 0 }"
        >
        <div>
          <ve-histogram :data="activiData"></ve-histogram>
        </div>
        </a-card>
      </a-col>
      <a-col :span="8">
        <a-card :bordered="false">
          <div class="">
            <a-form layout="inline" label-align="left">
              <a-row :gutter="48">
                <a-col :md="8">
                    <a-form-item label="时间">
                      <a-space direction="vertical" :size="12">
                        <a-range-picker @change="publishDateChange" />
                      </a-space>
                    </a-form-item>
                  </a-col>

                <a-col :md="8">
                  <span>
                    <a-button type="primary" @click="recordCount">筛选</a-button>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </a-card>
        <a-card
          style="margin-bottom: 24px"
          :loading="radarLoading"
          :bordered="false"
          :body-style="{ padding: 0 }"
        >
        <div>
          <ve-line :data="publishData"></ve-line>
        </div>
       </a-col>
       <a-col :span="8">
        <a-card :bordered="false">
          <div class="">
            <a-form layout="inline" label-align="left">
              <a-row :gutter="48">
                <a-col :md="8">
                    <a-form-item label="时间">
                      <a-space direction="vertical" :size="12">
                        <a-range-picker @change="feedbackDateChange" />
                      </a-space>
                    </a-form-item>
                  </a-col>

                <a-col :md="8">
                  <span>
                    <a-button type="primary" @click="feedbackCount">筛选</a-button>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </a-card>
        <a-card
          style="margin-bottom: 24px"
          :loading="radarLoading"
          :bordered="false"
          :body-style="{ padding: 0 }"
        >
          <div>
            <ve-histogram :data="feedbackData"></ve-histogram>
          </div>
        </a-card>
       </a-col>
    </a-row>

  </div>
</template>

<script>
import request from '@/utils/request'


export default {
  name: 'Statistics',
  components: {
  },
  data () {
    return {
      data: [],
      total: 0,
      // 查询参数
      queryParam: {
        created_start_at: '',
        created_end_at: '',
      },
      publishQueryParam: {
        created_start_at: '',
        created_end_at: '',
      },
      feedbackQueryParam: {
        created_start_at: '',
        created_end_at: '',
      },
      radio: 1,
      activiData: {
        columns: ['日期', '小程序用户活跃度'],
        rows: []
      },
      publishData: {
        columns: ['日期', '随意记', '科学记'],
        rows: []
      },
      feedbackData: {
        columns: ['日期', '小程序意见反馈数量'],
        rows: []
      }
    }
  },
  filters: {
  },
  created () {
    this.loadData()
    this.recordCount()
    this.feedbackCount()
  },
  computed: {
  },
  methods: {
    loadData() {
      request({
        url: "/statistics/active",
        method: 'get',
        params: this.queryParam
      }).then(res => {
        this.activiData.rows = res.data
      })
    },
    recordCount() {
      request({
        url: "/statistics/record",
        method: 'get',
        params: this.publishQueryParam
      }).then(res => {
        this.publishData.rows = res.data
      })
    },
    feedbackCount() {
      request({
        url: "/statistics/feedback",
        method: 'get',
        params: this.feedbackQueryParam
      }).then(res => {
        this.feedbackData.rows = res.data
      })
    },
    registerDateChange(data, dataString) {
      console.log(data, dataString)
      this.queryParam.created_start_at = dataString[0]
      this.queryParam.created_end_at = dataString[1]
    },
    publishDateChange(data, dataString) {
      console.log(data, dataString)
      this.publishQueryParam.created_start_at = dataString[0]
      this.publishQueryParam.created_end_at = dataString[1]
    },
    feedbackDateChange(data, dataString) {
      console.log(data, dataString)
      this.feedbackQueryParam.created_start_at = dataString[0]
      this.feedbackQueryParam.created_end_at = dataString[1]
    }

  }
}
</script>
